export default {
    zh:{
        bank:{
            queryParam:{
                name:'名称',
                name_placeholder:'请输入名称',
            },
            columns:{
                F_NAME:'名称',
            },
            form:{
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule: '请输入名称',
            },
        }
    },
    en:{
        bank:{
            queryParam:{
                name:'名称',
                name_placeholder:'请输入名称',
            },
            columns:{
                F_NAME:'名称',
            },
            form:{
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule: '请输入名称',
            },
        }
    }
}