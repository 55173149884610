import BankCard from './BankCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import BankListUtil,{IBankListDataObj} from './bankListUtil';
import language from './bankLanguage'
export default defineComponent ({
    name: 'BankList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IBankListDataObj=reactive<IBankListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: BankCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/bank/pageData')
            },
            otherParams:{},
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new BankListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            // console.log(utils.UtilPub.FormatNumber('51.004800',2))
            // utils.UtilPub.FormatNumber('51.004800',2)
            // utils.UtilPub.toAmountFormat(51.004800,false,2)
            // utils.UtilPub.toAmountFormat(22.915200,false,2)
        })
        //---------------------------computed------------

        const formatPageInfo=(options:any)=>{
            //找到需要格式化的列
            //options.pageInfo.columns.forEach((item:any)=>{
            //    if(item.prop=='F_DY_FLAG')item.formatter=utils.UtilPub.formatterYesNoStatus;
            //})
            return options;
        }

        return{
            ...toRefs(dataObj),formatPageInfo
        }
    }
});