import BaseBean from "@/utils/BaseBean";

export interface IBankListDataObj {
    utilInst:BankListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class BankListUtil extends BaseBean{
    public dataObj:IBankListDataObj

    constructor(proxy:any,dataObj:IBankListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}